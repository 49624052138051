import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "./Faqs.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  borderTopLeftRadius: "0px",
  borderTopRightRadius: "0px",
  "&:nth-child(2)": {
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
  },
  "&:last-child": {
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(248, 248, 248, 0.5)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FAQS() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="vendor_faq_accordian">
      <div className="title_Acc">Frequently Asked Questions (FAQs)</div>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>What happens in a spa in India?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div
              style={{
                paddingLeft: "10px",
              }}
            >
              <p>
                In India, spa experiences can vary greatly depending on the
                location, the type of spa, and the specific services offered.
                However, here are some common experiences you might find in a
                spa in India:
              </p>
              <br />
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Ayurvedic Treatments:
                  </strong>{" "}
                  Many spas in India offer traditional Ayurvedic treatments,
                  which are based on ancient Indian healing practices. These
                  treatments often involve herbal oils, massages, and other
                  therapies aimed at balancing the body's energies.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Massages:
                  </strong>{" "}
                  Indian spas are known for their massages, which can range from
                  relaxing to therapeutic. Popular massage techniques include
                  Ayurvedic massage, Swedish massage, deep tissue massage, and
                  hot stone massage.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Yoga and Meditation:
                  </strong>{" "}
                  Some spas in India offer yoga and meditation sessions as part
                  of their wellness packages. These sessions may be conducted by
                  experienced instructors and can help guests relax, improve
                  flexibility, and reduce stress.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Steam Rooms and Saunas:
                  </strong>{" "}
                  Many Indian spas have steam rooms and saunas where guests can
                  relax and detoxify their bodies. These facilities are often
                  complemented by cold plunge pools or showers for contrast
                  therapy.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Beauty Treatments:
                  </strong>{" "}
                  Indian spas often offer a range of beauty treatments such as
                  facials, body scrubs, and manicures/pedicures using natural
                  ingredients like herbs, spices, and fruits.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Specialized Therapies:
                  </strong>{" "}
                  Depending on the spa, you may find specialized therapies such
                  as hydrotherapy, reflexology, aromatherapy, and even sound
                  therapy.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Nutrition and Wellness Counseling:
                  </strong>{" "}
                  Some spas offer consultations with nutritionists or wellness
                  experts who provide advice on healthy eating, lifestyle
                  changes, and holistic wellness.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Cultural Elements:
                  </strong>{" "}
                  In some spas, especially those located in heritage properties
                  or resorts, you may find cultural elements incorporated into
                  the experience, such as traditional music, dance performances,
                  or rituals.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Detox Programs:
                  </strong>{" "}
                  Some spas in India offer detox programs that include cleansing
                  diets, herbal supplements, and treatments aimed at eliminating
                  toxins from the body.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Customized Packages:
                  </strong>{" "}
                  Many spas offer customized packages tailored to individual
                  needs, combining various treatments and therapies to address
                  specific health or wellness goals.
                </li>
              </ul>
              <br />
              Overall, a spa experience in India is often designed to promote
              relaxation, rejuvenation, and holistic well-being, drawing from
              traditional Indian healing practices and modern wellness
              techniques.
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Which massage is best in India?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div style={{
              paddingLeft: "10px",
            }}>
              <p>
                The best massage in India can be subjective and depends on
                personal preferences, health needs, and the desired outcome.
                However, some of the popular and highly regarded massages in
                India include:
              </p>
              <br />
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Ayurvedic Massage:
                  </strong>{" "}
                  Based on Ayurveda, an ancient Indian system of medicine,
                  Ayurvedic massages use herbal oils and specific techniques to
                  balance the body's energies (doshas). Abhyanga is a common
                  Ayurvedic full-body massage that aims to promote relaxation,
                  improve circulation, and detoxify the body.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Swedish Massage:
                  </strong>{" "}
                  Known for its gentle and relaxing strokes, Swedish massage is
                  widely offered in spas across India. It focuses on promoting
                  overall relaxation, easing tension, and improving blood
                  circulation.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Deep Tissue Massage:
                  </strong>{" "}
                  Ideal for individuals with muscle tension and chronic pain,
                  deep tissue massage involves applying firm pressure to reach
                  deeper layers of muscles and connective tissue. It can help
                  release knots and alleviate stiffness.{" "}
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Hot Stone Massage:
                  </strong>{" "}
                  This massage involves placing heated stones on specific points
                  of the body. The warmth from the stones helps relax muscles,
                  easing tension and promoting a sense of well-being.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Thai Massage:
                  </strong>{" "}
                  Originating from Thailand, Thai massage combines acupressure,
                  yoga-like stretches, and massage. It aims to improve
                  flexibility, increase energy flow, and release tension. Many
                  spas in India offer traditional Thai massages.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Shirodhara:
                  </strong>{" "}
                  While not a traditional massage, Shirodhara is a unique
                  Ayurvedic therapy where warm herbal oil is poured in a
                  continuous stream onto the forehead. It is known for inducing
                  deep relaxation and calming the nervous system.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Balinese Massage:
                  </strong>{" "}
                  Inspired by traditional Indonesian techniques, Balinese
                  massage combines acupressure, reflexology, and gentle
                  stretching. It aims to improve blood circulation, relieve
                  muscle tension, and promote relaxation.
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <strong style={{ textDecoration: "underline" }}>
                    Foot Reflexology:
                  </strong>{" "}
                  This massage focuses on pressure points on the feet that
                  correspond to various organs and systems in the body. Foot
                  reflexology is believed to promote overall well-being and
                  balance.
                </li>
              </ul>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>What do you wear to a full-body massage?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p>
                    One common question that often crosses the minds of spa-goers is, "What should I wear?" The answer is comfort. Most spas provide comfortable robes and slippers for you to change into before your massage.
                    <br />
                    However, it's advisable to wear something easy to slip out of, as you'll want to be in minimal clothing during the massage. Remember, the focus is on your comfort, so wear whatever allows you to relax and enjoy the experience.

                </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
