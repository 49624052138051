import React, { useEffect, useState } from "react";
import "./listpage.css";
import { Link, useParams, useLocation } from "react-router-dom";
import PopularArea from "../MainPage/PopularArea/PopularArea";
import Slider from "../Common/Slider/Slider";
import { capitalizeAndFormat } from "../functions/generalFun";
import {
  getAllAreaSpas,
  getBeautySpas,
  getBodyMassageSpas,
  getLuxuriosSpas,
  getMenSpas,
  getWomenSpas,
  getTopRatedSpas,
  getBodyMassageCenters,
  getBestSpas,
  getThaiBodyMassage,
} from "./pageapi";

import OtherListCard from "./listcard/OtherListCard";
import Header from "../Common/Navbar/Header";
import FooterN from "../Common/Footer/FooterN";
import smallAndFormat from "../Common/functions/smallAndFormat";

const AreaListPage = ({ title, subtitle, name }) => {
  const params = useParams();
  const location = useLocation();
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [city, setCity] = useState(params?.city);
  const [type, setType] = useState("");
  const [area, setArea] = useState(params?.area);

  let path = window.location?.pathname?.split("/")[2];

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const cityIndex = pathSegments.indexOf(params.city);

    if (cityIndex !== -1 && cityIndex + 1 < pathSegments.length) {
      setType(pathSegments[cityIndex + 1]);
    }
  }, [location.pathname, params.city]);

  const NavOptions = [
    { tag: "All", link: "spas" },
    { tag: "Top Rated Spas", link: "topratedspas" },
    { tag: "Luxurious Spas", link: "luxuriousspas" },
    { tag: "Best Spas", link: "bestspas" },
    { tag: "Beauty Spas", link: "beautyspas" },
    { tag: "Thai Body Massage Spas", link: "thaimassage" },
    { tag: "Men Spas", link: "menspas" },
    { tag: "Women Spas", link: "womenspas" },
    { tag: "Body Massage Spas", link: "bodymassagespas" },
    { tag: "Body Massage Center", link: "bodymassagecenter" },
  ];

  useEffect(() => {
    console.log("type : ", type);
    setListData([]);
    async function fetchData() {
      setIsDataLoading(true);
      let data = [];
      switch (name) {
        case "areapage":
          console.log("name : ", name);
          data = await getAllAreaSpas(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "beautySpas":
          console.log("name : ", name);
          data = await getBeautySpas(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "bodyMassageSpas":
          console.log("name : ", name);
          data = await getBodyMassageSpas(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "topRatedSpas":
          console.log("name : ", name);
          data = await getTopRatedSpas(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "luxuriousSpas":
          console.log("name : ", name);
          data = await getLuxuriosSpas(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "menSpas":
          console.log("name : ", name);
          data = await getMenSpas(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "womenSpas":
          console.log("name : ", name);
          data = await getWomenSpas(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "bodyMassageCenters":
          console.log("name : ", name);
          data = await getBodyMassageCenters(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "bestSpas":
          console.log("name : ", name);
          data = await getBestSpas(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
          break;
        case "thaiBodyMassage":
          console.log("name : ", name);
          data = await getThaiBodyMassage(
            capitalizeAndFormat(city),
            capitalizeAndFormat(area),
            page
          );
        default:
          break;
      }

      if (Array.isArray(data.results)) {
        setListData((prevValue) => [...prevValue, ...data.results]);
        !data.next && setHasMore(false);
      } else {
        console.error("Data results is not an array:", data.results);
      }
      console.log("Data : ", data);
      console.log("List data : ", listData);
      console.log("Name : ", name);
      setIsDataLoading(false);
    }

    fetchData();
  }, [page, city, area, name]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    setArea(params?.area);
    setListData([]);
  }, [params?.area]);

  useEffect(() => {
    setCity(params?.city);
  }, [params?.city]);


  useEffect(() => {
    if (name) {

      // set meta info here

      switch (name) {
        case "areapage":
          document.title = `List of Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Best Spas, Best Spa Therapies in ${capitalizeAndFormat(params.area)}, Best Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Best Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;
        case "beautySpas":
          document.title = `List of Beauty Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Beauty Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Luxurious Spas In ${capitalizeAndFormat(params?.area)}, Beauty Spas Therapies In ${capitalizeAndFormat(params?.area)}, Beauty Spas Centres in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Best Beauty Spas near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;
        case "bodyMassageSpas":
          document.title = `List of Body Massage spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Body Massage Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Body Massage Spa In ${capitalizeAndFormat(params?.area)}, Body Massage Spas Therapies In ${capitalizeAndFormat(params?.area)}, Body Massage Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Body Massage Spas near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;
        case "topRatedSpas":
          document.title = `List of Top Rated Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Top Rated Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Top Rated Spas In ${capitalizeAndFormat(params?.area)}, Top Rated Spas Therapies In ${capitalizeAndFormat(params?.area)}, Top Rated Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Top Rated Spas near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;  
        case "luxuriousSpas":
          document.title = `List of Luxurious Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Luxurious Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Luxurious Spas In ${capitalizeAndFormat(params?.area)}, Luxurious Spa Therapies In ${capitalizeAndFormat(params?.area)}, Luxurious Spa Centres in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Best Luxurious Spas near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;

        case "menSpas":
          document.title = `List of Spas for men in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Spas for men in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Spas for Men In ${capitalizeAndFormat(params?.area)}, Spas Therapies For Men In ${capitalizeAndFormat(params?.area)}, Spas Centres for Men in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Best Spas For Men near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;
        
        case "womenSpas":
          document.title = `List of Spas for Women in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Spas for Women in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Spas for Women In ${capitalizeAndFormat(params?.area)}, Spas Therapies For Women In ${capitalizeAndFormat(params?.area)}, Spas Centres for Women in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Best Spas For Women near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;

        case "bodyMassageCenters":
          document.title = `List of Body Massage Centers in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Body Massage Centers in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Body Massage Centers In ${capitalizeAndFormat(params?.area)}, Body Massage Centers Therapies In ${capitalizeAndFormat(params?.area)}, Body Massage Centers in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Body Massage Centers near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;

        case "bestSpas":
          document.title = `List of Best Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Body Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Body Spa In ${capitalizeAndFormat(params?.area)}, Body Spas Therapies In ${capitalizeAndFormat(params?.area)}, Body Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Body Spas near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;
        
        case "thaiBodyMassage":
          document.title = `List of Thai Massage spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} - Trakky`;
          document.querySelector('meta[name="description"]').setAttribute("content", `Best Thai Massage Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Find Now Thai Massage Spa In ${capitalizeAndFormat(params?.area)}, Thai Massage Spas Therapies In ${capitalizeAndFormat(params?.area)}, Thai Massage Spas in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}. Get Phone Numbers, Address, Reviews, Photos, Maps for Thai Massage Spas near me in ${capitalizeAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)} on Trakky.`);
          break;
        
        
        default:
          break;
      }
    }
  } , [name]);

  return (
    <>
      {/* <Helmet>

<title>{getMetaTitleDetails()}</title>
<meta name="description" content={getMetaDescDetails()} />

</Helmet> */}
      {/* <Hero />
      <div className="listpage-nav-links">
        <ul>
          {city &&
            area &&
            NavOptions.map((item, index) => (
              <li key={index} className={item.link === type ? "active" : ""}>
                <Link to={`/${city}/${item.link}/${area}`}>{item.tag}</Link>
              </li>
            ))}
        </ul>
      </div>
      <div className="listpage-heading">
        <h1>{area && (title += " " + capitalizeAndFormat(area))}</h1>
      </div>

      {
        <div className="listpage-offer-container">
          <OfferContainer />
        </div>
      }
      <div className="listpage-heading">
        <h1>{area && `${subtitle} ` + capitalizeAndFormat(area)}</h1>
        {listData.length === 0 ? (
          <>
            <p
              style={{
                fontFamily: "Arial, sans-serif",
                fontStyle: "italic",
                fontSize: "14px",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Hold on to Your Seats! Something Big Is Coming Your Way Soon!
            </p>
          </>
        ) : (
          <>
            <div className="lp-card-listing-container">
              {listData.map((item, index) => (
                <ListCard key={index} data={item} />
              ))}
            </div>
          </>
        )}
      </div>
      {hasMore && (
        <div className="lp-load-more">
          {!isDataLoading ? (
            <button onClick={handleLoadMore}>View More</button>
          ) : (
            <div className="lp-loader"></div>
          )}
        </div>
      )}

      <PopularArea />
      <Footer city={params?.city || "ahmedabad"} /> */}
      <div className="N-list-page-container relative">
        <div className="N-list-page-background-color"></div>
        <Header />
        <div className="L-list-page-option-n-filter">
          {city &&
            area &&
            NavOptions.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    item.link == path
                      ? "!bg-[#512DC8] !text-white text-sm sort-box "
                      : " sort-box"
                  }
                >
                  <Link
                    to={`/${encodeURIComponent(city)}/${
                      item.link
                    }/${encodeURIComponent(area)}`}
                    className=" text-inherit"
                  >
                    {item.tag}
                  </Link>
                </div>
              );
            })}
        </div>

        {/* <OfferComponentN title={"Grab The Best Offers"} area={params?.area} /> */}

        {
          <div className="N-listpage-heading">
            <h1>
              {name === "areapage"
                ? `List of spas In ${smallAndFormat(area)}`
                : name === "bodyMassageCenters"
                ? `List of ${subtitle} in ${smallAndFormat(
                    params?.area
                  )}, ${capitalizeAndFormat(params?.city)}`
                : name === "menSpas" ? `List of spas for men in ${smallAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}` : name === "womenSpas" ? `List of spas for women in ${smallAndFormat(params?.area)}, ${capitalizeAndFormat(params?.city)}` : `List of ${subtitle} spas in ${smallAndFormat(
                    params?.area
                  )}, ${capitalizeAndFormat(params?.city)}`}
            </h1>
          </div>
        }
        <div className="N-lp-card-listing-container">
          {isDataLoading ? (
            <div className="N-lp-load-more">
              <div className="N-lp-loader"></div>
            </div>
          ) : listData?.length > 0 ? (
            listData?.map((item, index) => {
              return <OtherListCard key={index} data={item} />;
            })
          ) : (
            <div className=" mx-auto h-20 flex items-center font-semibold">
              No spa found
            </div>
          )}
        </div>
        {hasMore &&
          (!isDataLoading ? (
            <div className="lp-load-more">
              <button
                onClick={() => {
                  setPage((prevValue) => prevValue + 1);
                }}
              >
                View More spa
              </button>
            </div>
          ) : (
            <div className="lp-load-more">
              <div className="lp-loader"></div>
            </div>
          ))}
        <PopularArea />
        <FooterN city={params?.city || "ahmedabad"} />
      </div>
    </>
  );
};

export default AreaListPage;

const OfferContainer = () => {
  const [offersData, setOffersData] = useState([]);

  const params = useParams();
  const city = capitalizeAndFormat(params?.city);
  const area = capitalizeAndFormat(params?.area);

  let path = window.location.pathname.split("/")[3];

  const getOffer = () => {
    const requestOption = {
      method: "GET",
      header: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      `https://trakky.in:8000/spas/offer/?city=${city}&area=${area}`,
      requestOption
    )
      .then((res) => res.json())
      .then((data) => {
        setOffersData(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getOffer();
  }, [path]);

  return (
    offersData.length > 0 && (
      <div
        className="slider__outer-container offer__container"
        style={{ width: "100%" }}
      >
        <div className="slider__header" style={{ margin: 0 }}>
          <h2>Grab the best deals</h2>
        </div>
        <Slider cardList={offersData} _name={"offer"} />
      </div>
    )
  );
};
