import React from "react";
import "../forms/form.css";
import { useState } from "react";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import {toast,Toaster} from "react-hot-toast";
import { Link , useNavigate } from 'react-router-dom'; 
import { Helmet } from "react-helmet";

const SpaRegistration = () => {

  const navigate = useNavigate()

  document.title = "Spa Registration | Trakky";
  const [formData, setFormData] = useState({
    businessName: "",
    contactDetails: "",
    ownerName: "",
    ownerContact: "",
    whatsappNumber: "",
    city: "",
    address: "",
    otherCity: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("spa_name", formData.businessName);
    form.append("spa_contact_number", formData.contactDetails);
    form.append("owner_name", formData.ownerName);
    form.append("owner_contact_number", formData.ownerContact);
    form.append("whatsapp_number", formData.whatsappNumber);
    form.append("address", formData.address);
    form.append(
      "city",
      formData.city === "other" ? formData.otherCity : formData.city
    );

    try {
      const response = await fetch(
        "https://trakky.in:8000/spas/register-spa/",
        {
          method: "POST",
          body: form,
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          toast.success("Spa registered successfully. Our team will contact you soon");
          setFormData({
            businessName: "",
            contactDetails: "",
            ownerName: "",
            ownerContact: "",
            whatsappNumber: "",
            city: "",
            address: "",
            otherCity: "",
          });

          navigate('/')
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
          toast.error("An error occurred while registering the spa");
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Register your spa on trakky for new customers</title>
        <meta
          name="description"
          content="Start your spa business journey with Trakky! Create your spa's page and sign up for spa business growth. For assistance, call us at +91 63-55-16-73-04."
        />
        <meta
          name="keywords"
          content="spa software, online spa booking, spa business growth"
        />
      </Helmet>
      <Header />
      <div
        className="form-registration-container"
        style={{
          marginBottom: "50px",
        }}
      >
        <form method="post" onSubmit={handleSubmit}>
          <div className="row">
            <h1 className="form-title !text-lg md:!text-xl !font-semibold lg:!text-2xl !text-center w-full">Register your spa for business growth on trakky</h1>
          </div>
          <div className="row">
            <div className="input-box inp-business-name col-1">
              <label htmlFor="business-name">Business Name of the Spa</label>
              <input
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                id="business-name"
                placeholder="Enter Business Name"
                required
              />
            </div>
            <div className="input-box inp-contact-details col-2">
              <label htmlFor="contact-details">Contact Details of Spa</label>
              <input
                type="number"
                name="contactDetails"
                value={formData.contactDetails}
                onChange={handleChange}
                id="contact-details"
                placeholder="Enter Contact Details"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="input-box inp-owner-contact col-2">
              <label htmlFor="owner-contact">WhatsApp Number</label>
              <input
                type="tel"
                name="whatsappNumber"
                value={formData.whatsappNumber}
                onChange={handleChange}
                id="owner-contact"
                placeholder="Enter Owner's Contact Number"
                required
              />
            </div>
            <div className="input-box inp-owner-name col-1">
              <label htmlFor="owner-name">Spa Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                id="owner-name"
                placeholder="Enter Spa Address"
                required
              />
            </div>
          </div>
          {/* Name of the owner */}
          <div className="row">
            <div className="input-box inp-owner-name col-1">
              <label htmlFor="owner-name">Name of the owner</label>
              <input
                type="text"
                name="ownerName"
                value={formData.ownerName}
                onChange={handleChange}
                id="owner-name"
                placeholder="Enter Owner's Name"
                required
              />
            </div>
            <div className="input-box inp-owner-contact col-2">
              <label htmlFor="owner-contact">Contact Number of the Owner</label>
              <input
                type="tel"
                name="ownerContact"
                value={formData.ownerContact}
                onChange={handleChange}
                id="owner-contact"
                placeholder="Enter Owner's Contact Number"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="input-box inp-city col-2">
              <label htmlFor="city">City where your spa is located</label>
              <select
                name="city"
                id="city"
                value={formData.city}
                onChange={handleChange}
                required
              >
                <option value="">Select City</option>
                <option value="ahmedabad">Ahmedabad</option>
                <option value="surat">Surat</option>
                <option value="gandhinagar">Gandhinagar</option>
                <option value="vadodara">Vadodara</option>
                <option value="rajkot">Rajkot</option>
                <option value="bangalore">Bangalore</option>
                <option value="mumbai">Mumbai</option>
                <option value="pune">Pune</option>
                <option value="hydrabad">Hydrabad</option>
                <option value="chennai">Chennai</option>
                <option value="delhi">Delhi</option>
                <option value="gurugram">Gurugram</option>
                <option value="noida">Noida</option>
                <option value="grater Noida">Grater Noida</option>
                <option value="kolkata">Kolkata</option>
                <option value="jaipur">Jaipur</option>
                <option value="udaipur">Udaipur</option>
                <option value="jodhpur">Jodhpur</option>
                <option value="indore">Indore</option>
                <option value="bhopal">Bhopal</option>
                <option value="raipur">Raipur</option>
                <option value="amritsar">Amritsar</option>
                <option value="ludhiana">Ludhiana</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="input-box inp-city col-2">
              {formData.city === "other" && (
                <>
                  <label htmlFor="city">City where your spa is located</label>
                  <input
                    type="text"
                    name="otherCity"
                    id="city"
                    value={formData.otherCity}
                    onChange={handleChange}
                    placeholder="Enter City"
                    required
                  />
                </>
              )}
            </div>
          </div>

          <div className="submit-btn row">
            <Link to="/" ><button type="submit" className="submit-primary">Close</button></Link>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>

      <Footer />
      <Toaster position="top-right" />
    </>
  );
};

export default SpaRegistration;
