import React from "react";
import styles from "./PartnerUs.module.css";
export default function PartnerUs() {
  return (
    <div className={styles.Vendor_partnerus_main}>
      <div className={styles.Vendor_partnerus_content}>
        <div className={styles.Vendor_partnerus_content_body}>
          <p className={styles.Vendor_partnerus_p1}>How to get more customers?</p>
          <p className={styles.Vendor_partnerus_p2}>
            Create your spa profile and start receiving customers online
          </p>
        </div>
        <a href="https://spa.trakky.in/spaRegistration" target="_blank" rel="noreferrer" style={{padding:"0 0 0 10px"}}>
          <button className={styles.Vendor_partnerus_button}>Register Spa</button>
        </a>
      </div>
    </div>
  );
}
