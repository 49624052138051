import React, { useState, useEffect } from "react";
import styles from "./Hero.module.css";
import Cards from "./Cards";
import validator from "validator";
function Hero() {
  const [firstName, setFirstName] = useState('');
  const [lastName , setLastName] = useState('');
  const [msg, setmsg] = useState('');
  const [firstNameError, setFirstNameError] = useState("hidden");
  const [lastNameError, setLastNameError] = useState("hidden");
  const [msgerror, setMsgError] = useState("hidden");
  const [mail, setMail] = useState("");
  const [number, setNumber] = useState("");
  const [emailError, setEmailError] = useState("hidden");
  const [numberError, setNumberError] = useState("hidden");

  const [termandcondition, setTermandcondition] = useState(false);
  const [futureUpdates, setFutureUpdates] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setFirstNameError("hidden")
    setLastNameError("hidden")
    setMsgError("hidden")
    setNumberError("hidden");
    setEmailError("hidden");
    if (mail && !validator.isEmail(mail)) {
      setEmailError("visible");
    }
    if (number && !validator.isMobilePhone(number, "en-IN")) {
      setNumberError("visible");
    }
    if(validator.isEmpty(firstName)){
         setFirstNameError('visible')
    }
    if(validator.isEmpty(lastName)){
      setLastNameError('visible')
    }
    if(validator.isEmpty(msg)){
        setMsgError('visible')
   }
   if(validator.isEmpty(mail)){
    setEmailError("visible");
   }
   if(validator.isEmpty(number)){
    setNumberError("visible");
   }

   if ( !validator.isEmail(mail) || !validator.isMobilePhone(number, "en-IN") || validator.isEmpty(firstName) || validator.isEmpty(lastName) || validator.isEmpty(msg) || validator.isEmpty(mail) || validator.isEmpty(number) ) {
    return;
  }

  try {
    let url = 'https://trakky.in:8000/salons/contact-us/'

    let formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', mail);
    formData.append('phone_no', number);
    formData.append('message', msg);
    formData.append('platform', 'spa');
    formData.append("terms_and_conditions", termandcondition);
    formData.append("future_updates", futureUpdates);


    let response = await fetch(url, {
      method: 'POST',
      body: formData
    })

    let data = await response.json()

    if (response.status === 201) {
      setFirstName('')
      setLastName('')
      setMail('')
      setNumber('')
      setmsg('')
      setTermandcondition(false)
      setFutureUpdates(false)
      alert('Your message has been sent successfully')

    }
    else {
      alert('Something went wrong')
    }
  }
  catch (error) {
    console.log(error)
  }


  };
  return (
    <div className={styles.Contactus_Hero_main}>
      <div className={styles.Contactus_Hero_form}>
        <form action="" className={styles.Contactus_Hero_form_content}>
          <input
            type="text"
            id="firstname"
            placeholder=""
            autoComplete="off"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <label className={styles.Contactus_Hero_label_input} htmlFor="firstname">
            First Name
          </label>
          <p
            style={{
              fontSize: "10px",
              margin: "-20px 0 14px 5px",
              color: "red",
              visibility: firstNameError,
            }}
          >
            Please enter your First Name
          </p>
          <input
            type="text"
            id="lastname"
            placeholder=""
            value={lastName}
            autoComplete="off"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <label className={styles.Contactus_Hero_label_input} htmlFor="lastname">
            Last Name
          </label>
          <p
            style={{
              fontSize: "10px",
              margin: "-20px 0 14px 5px",
              color: "red",
              visibility: lastNameError,
            }}
          >
            Please enter your Last Name
          </p>

          <input
            autoComplete="off"
            type="mail"
            value={mail}
            placeholder=""
            id="email"
            onChange={(e) => {
              setMail(e.target.value);
            }}
          />
          <label className={styles.Contactus_Hero_label_input} htmlFor="email">
            Enter an Email Address
          </label>
          <p
            style={{
              fontSize: "10px",
              margin: "-20px 0 14px 5px",
              color: "red",
              visibility: emailError,
            }}
          >
            Please enter a valid Email Address
          </p>
          <input
            autoComplete="off"
            type="text"
            placeholder=""
            value={number}
            id="number"
            style={{ WebkitAppearance: "none" }}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
          <label className={styles.Contactus_Hero_label_input} htmlFor="number">
            Phone
          </label>
          <p
            style={{
              fontSize: "10px",
              margin: "-20px 0 14px 5px",
              color: "red",
              visibility: numberError,
            }}
          >
            Please enter a valid Mobile Number
          </p>
          <textarea
            onChange={(e) => {
              setmsg(e.target.value);
            }}
            autoComplete="off"
            name=""
            id="msg "
            value={msg}
            cols="58"
            rows="5"
            placeholder=""
            className={styles.Contactus_Hero_textarea}
          ></textarea>
          <label className={styles.Contactus_Hero_label_input} htmlFor="msg">
            Your Message
          </label>
          <p
            style={{
              fontSize: "10px",
              margin: "-20px 0 14px 5px",
              color: "red",
              visibility: msgerror,
            }}
          >
            Please enter your message
          </p>
          <div className={styles.Contactus_Hero_checkbox}>
            <input
              type="checkbox"
              id="termandcondition"
              value={termandcondition}
              onChange={(e) => {
                setTermandcondition(e.target.checked);
              }}
              style={{
                height: "23px",
                width: "15px",
                marginRight: "10px",
                flexShrink: "0",
              }}
            />
            <label htmlFor="termandcondition">
              I agree with terms & conditions
            </label>
          </div>
          <div
            className={styles.Contactus_Hero_checkbox}
            style={{ marginBottom: "20px" }}
          >
            <input
              type="checkbox"
              id="futureUpdates"
              onChange={(e) => {
                setFutureUpdates(e.target.checked);
              }}
              value={futureUpdates}
              style={{
                height: "23px",
                width: "15px",
                marginRight: "10px",
                flexShrink: "0",
              }}
            />
            <label htmlFor="futureUpdates">
            I agree to receive promotional updates on Whatsapp, SMS & email.
            </label>
          </div>
          <button
            className={styles.Contactus_Hero_button}
            type="submit"
            onClick={handleSubmit}
          >
            Send Message
          </button>
        </form>
      </div>
      <Cards />
    </div>
  );
}

export default Hero;
